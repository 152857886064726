import { render, staticRenderFns } from "./ddos-error-popup.html?vue&type=template&id=073a2503&scoped=true&external"
import script from "./ddos-error-popup.vue?vue&type=script&lang=js"
export * from "./ddos-error-popup.vue?vue&type=script&lang=js"
import style0 from "./ddos-error-popup.css?vue&type=style&index=0&id=073a2503&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073a2503",
  null
  
)

export default component.exports