import { render, staticRenderFns } from "./bread-crumbs.html?vue&type=template&id=3f5c019f&external"
import script from "./bread-crumbs.vue?vue&type=script&lang=js"
export * from "./bread-crumbs.vue?vue&type=script&lang=js"
import style0 from "./bread-crumbs.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports