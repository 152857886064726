<template>
  <div ref="videoContainer" class="shadow-lg mx-auto max-w-full size">
    <div class="circular-loader" v-if="isLoading">
      <svg viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </div>
    <video id="video" ref="videoPlayer" class="video-player" :poster="selectedVideo.image_url" @waiting="onBuffering"
      @playing="onPlaying"></video>
    <div class="video-overly-info" style="z-index: 1;">
      <h3 style="color: rgba(2,6,1,.1) !important;">{{ stamp }}</h3>
      <h3 style="color: rgba(2,6,1,.1) !important;">www.nearpeer.org</h3>
    </div>
    <div class="video-marquee" v-if="userStamp">
      <span class="text marquee-text">{{ userInfo }}</span>
    </div>
  </div>
</template>

<script>
import customStorageService from "../../../app-services/custom-storage-service";
let player;
let user = {};
let moment = require("moment");
export default {
  props: [
    "selectedVideo",
    "userData",
  ],
  data() {
    return {
      manifestUrl: "",
      posterUrl: '',
      stamp: "",
      userInfo: "",
      userStamp: true,
      isLoading: true,
    }

  },
  created() {
    this.manifestUrl = `https://video.nearpeer.org${this.selectedVideo.url_mpd}`
  },
  mounted() {
    const shaka = require('shaka-player/dist/shaka-player.ui.js');
    player = new shaka.Player(this.$refs.videoPlayer);
    const ui = new shaka.ui.Overlay(
      player,
      this.$refs.videoContainer,
      this.$refs.videoPlayer
    );
    const config = {
      addBufferingSpinner: false,
    };
    ui.getControls(config);

    player
      .load(this.manifestUrl)
      .then(() => {
        this.isLoading = false;
        // This runs if the asynchronous load is successful.
        console.log('The video has now been loaded!');

      })
      .catch(this.onError); // onError is executed if the asynchronous load fails.

    this.initializations();
    this.incrementPercent();
  },
  methods: {
    onBuffering() {
      this.isLoading = true; 
      this.incrementPercent();
    },
    onPlaying() {
      this.isLoading = false; 
    },
    incrementPercent() {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 3;

        if (percent >= 98) {
          percent = 98;
          clearInterval(interval);
        }

        this.$refs.videoContainer.querySelector('.circular-loader').setAttribute('data-percent', `${percent}%`);
      }, 250); 
    },
    onError(error) {
      console.error('Error code', error.code, 'object', error);
    },
    initializations() {
      user = customStorageService.getUser();
      if (user) {
        let date = new Date();
        this.userInfo =
          user.email +
          " , " +
          user.phone +
          " , " +
          moment(date).format("MM/DD/YYYY hh:mm");
        let monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        this.stamp = monthNames[date.getMonth()] + "-" + date.getFullYear();
      }
    },
  },
  watch: {
    selectedVideo: async function (newVal, oldVal) {
      this.manifestUrl = `https://video.nearpeer.org${newVal.url_mpd}`
      this.isLoading = true;
      player
        .load(this.manifestUrl)
        .then(() => {
          this.isLoading = false;
          // This runs if the asynchronous load is successful.
          console.log('The video has now been loaded!');
        })
        .catch(this.onError); // onError is executed if the asynchronous load fails.
    }
  }
};
</script>

<style>
@import '../../../../../node_modules/shaka-player/dist/controls.css';
/* Shaka player CSS import */

.size {
  height: 100%;
}

.video-player {
  width: 100%;
}

.video-marquee {
  padding: 5px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.marquee-text {
  z-index: 90;
}

.video-marquee .text {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.2);
  font-size: 18px;
  text-align: center;
  line-height: 1.6em;
  position: absolute;
  left: 0;
  top: -30px;
  animation: animTopBottom 180s 60s linear infinite;
  -webkit-animation: animTopBottom 180s 60s linear infinite;
  -moz-animation: animTopBottom 180s 60s linear infinite;
}

.shaka-spinner-container {
  display: none !important;
}


@keyframes animTopBottom {
  0% {
    top: 100%;
  }

  3% {
    top: -100%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circular-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.circular-loader:before {
  content: attr(data-percent);
  position: absolute;
  top: 55%;
  left: 55%;
  width: 50px;
  height: 50px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsla(0, 97%, 59%, 0.84);
  transform: translate(-55%, -55%);
  border-radius: 50%;

}

.circular-loader svg {
  width: 5.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.circular-loader circle {
  fill: none;
  stroke: hsl(0, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}


@media only screen and (max-width: 600px) {
  .video-marquee .text {
    font-size: 12px !important;
    animation: animTopBottom 290s 60s linear infinite !important;
    -webkit-animation: animTopBottom 290s 60s linear infinite !important;
    -moz-animation: animTopBottom 290s 60s linear infinite !important;
  }
}
</style>