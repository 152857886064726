import { render, staticRenderFns } from "./daq-quiz.html?vue&type=template&id=51c95f37&scoped=true&external"
import script from "./daq-quiz.vue?vue&type=script&lang=js"
export * from "./daq-quiz.vue?vue&type=script&lang=js"
import style0 from "./daq-quiz.css?vue&type=style&index=0&id=51c95f37&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c95f37",
  null
  
)

export default component.exports