<template src="./single-question-stat.html"></template>
<script>
import Doughnut from "../../../course-module/components/charts/Doughnut.vue";
import quizService from '../../services/quiz-service';
import toastr from "toastr";


export default {
  name: "app-single-question-stat",
  props: ["questionNo", "question","quiz_id","user" ,"totalAttempts", "toThePoint"],
  components: {
    "app-Doughnut": Doughnut
  },
  data: function() {
    return {
      correctPercentage: 0,
      difficultyLevel: "",
      alphabets: ["A", "B", "C", "D", "E", "F", "G", "H"],
      detectedUrdu: null,
      reportQuiz:false,
      reportQuizDescArr:[],
      reportQuizDesc:'',
      reportField:false
    };
  },
  methods: {
    close() {
      // this.$emit("buttonClick");
    },
    cancelReportQuiz() {
      console.log("popup-close");
      this.reportQuiz = false;
    },
    reportProblem() {
      console.log("popup-open");
      this.reportQuiz = true;
      this.reportQuizDescArr = [];
      this.reportQuizDesc = "";
    },
    reviewAll: function () {
      this.$emit("reviewAll", {});
    },
    async postReportQuiz() {
      if (
        this.reportQuizDesc == '' &&
        this.reportQuizDescArr.length == 0
      ) {
        console.log("i am called----");
        toastr.info("Please Specify The Problem.");
        return ;
      }
      console.log("popup-close");
      this.reportQuiz = false;
      this.reportField = false;
      if (this.reportQuizDesc.length > 0) {
        this.reportQuizDescArr.push(this.reportQuizDesc);
      }
      let reportDesc = {
        report_des_arr: this.reportQuizDescArr
      };
      let reportDescAr;
      reportDescAr = this.reportQuizDescArr;

      let obj2 = {
        report_type: "quiz",
        quiz_id: this.quiz_id,
        user_id: this.user.id,
        report_desc: reportDescAr,
        question_no:  this.questionNo,
        question_id:  this.question._id
      };
      let [reportSent, reportSent2] = await Promise.all([
        // quizService.createReport(obj),
        quizService.createReportInQuizBackend(obj2)
      ]);
      if (reportSent.data.status == "success") {
        toastr.info("Successfully reported");
        this.reportQuizDescArr = [];
        this.reportQuizDesc = "";
      } else {
        toastr.error("Something went wrong!");
      }
    },
    mathCreator: function(id, data) {
      setTimeout(() => {
        let mathFieldSpan = document.getElementById(id);
        let MQ = MathQuill.getInterface(2);
        let mathField = MQ.MathField(mathFieldSpan, {
          spaceBehavesLikeTab: false // configurable
        });
        if (!data.match(/\\\s/g)) {
          data = data.replace(/\s+/g, "\\ ");
        }
        if (data !== "" && data !== "null") {
          mathField.latex(data);
        }
      }, 500);
    },
    calculatePercentage: function() {
      (this.question.tags || []).forEach(element => {
        if (element.tag_type === "level") {
          this.difficultyLevel = element.tag_name;
        }
      });
      if (
        this.question.correctCount &&
        this.totalAttempts &&
        this.totalAttempts > 0
      ) {
        this.correctPercentage = (
          (this.question.correctCount / this.totalAttempts) *
          100
        ).toFixed(0);
        this.correctPercentage = parseInt(this.correctPercentage);
      } else {
        this.correctPercentage = 0;
      }
    }
  },
  watch: {
    totalAttempts: function(val) {
      this.calculatePercentage();
    },
    question: function(val) {
      this.calculatePercentage();
      if (this.question.question_id) {
        this.question = _.merge(this.question, this.question.question_id);
        delete this.question.question_id;
      }
    },
    questionNo: function() {
      this.calculatePercentage();
    }
  },
  mounted() {
    this.calculatePercentage();
    if (this.question.question_id) {
      this.question = _.merge(this.question, this.question.question_id);
      delete this.question.question_id;
      this.$forceUpdate();
    }
    let textToDetect =  this.question.question;
    let pattern = /[\u0600-\u06FF]/;
    if (pattern.test(textToDetect)) {
      this.detectedUrdu = true
    } else {
      this.detectedUrdu = false
    }
    console.log("🚀 ~ mounted ~ detectedUrdu:", detectedUrdu)
  }
};
</script>
<style src="./single-question-stat.css" scoped></style>
